<template>
    <div>
        <h2>Firebase Realtime Database Operations</h2>
        <button @click="addUserData('user1', { score: 1, level: 1 })">Add User Data</button>
        <button @click="getUserData('user1')">Get User Data</button>
        <button @click="getAllData()">Get All Data</button>
    </div>
</template>

<script>
import { getDatabase, ref, push, set, get, child, onChildChanged } from "firebase/database";

const database = getDatabase();

export default {
    name: 'FirebaseRealtimeExample',

    methods: {
        constructorUser() {
            return {
                displayName: '',
                assistances: 0,
                points: 0,
                level: 0,
                lastAssistance: '',
                lastAssistanceDate: '',
            }
        },


        // Add data for a user
        addUserData(userId, data) {
            set(ref(database, 'stats/' + userId), data)
                .then(() => console.log('Data added for user:', userId))
                .catch((error) => console.error('Error adding data:', error));
        },

        // Get data for a specific user
        getUserData(userId) {
            const userRef = ref(database, 'stats/' + userId);
            get(userRef).then((snapshot) => {
                if (snapshot.exists()) {
                    console.log(snapshot.val());
                } else {
                    console.log('No data available for user:', userId);
                }
            }).catch((error) => {
                console.error('Error fetching user data:', error);
            });
        },

        // Get all users' data
        getAllData() {
            const statsRef = ref(database, 'stats/');
            get(statsRef).then((snapshot) => {
                if (snapshot.exists()) {
                    console.log(snapshot.val());
                } else {
                    console.log('No data available');
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        }
    }
}
</script>

<style>
/* Add your component styles here */
</style>
